import React, { useContext } from "react"
import { Formik, Field } from 'formik'
import validationSchema from './validationSchema'
import { FirebaseContext } from "gatsby-plugin-firebase"

import {  
  FaRegHandshake,
  FaRegCheckCircle,
  FaStar
} from 'react-icons/fa';

const DreamFeatureForm = ({source, type}) => {
  const firebase = useContext(FirebaseContext)

  return (
    <Formik
      initialValues={{ feature: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const { feature } = values;
        firebase.analytics().logEvent(source + "_" + type + "_feature_form_submission")

        firebase
          .firestore()
          .collection("/dreamFeature_" + source + "_" + type)
          .add({
            feature,
            created: firebase.firestore.Timestamp.fromDate(new Date())
          })
          .then(() => {
            setSubmitting(false)
            setStatus('success')
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!");                            //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        status,
        isSubmitting,
        handleSubmit,
      }) => (<form
        name={"dream_feature_" + source + "_" + type}
        onSubmit={handleSubmit}
      >

        <div className={ 'feature has-feature-box ' + (type == 'Ideal' ? ' is-cyan' : type === 'Realistic' ? ' is-light-golden' : ' is-light-pink')}>
            <h4 className='title is-4 has-text-grey-dark'>{type} Feature</h4>
            <figure className={type !== 'Ideal' ? 'is-hidden' : 'image is-128x128 has-image-centered mb-1'}>
                <FaRegHandshake size={128} />
            </figure>

            <figure className={type !== 'Realistic' ? 'is-hidden' : 'image is-128x128 has-image-centered mb-1'}>
                <FaRegCheckCircle size={128} />
            </figure>            

            <figure className={type !== 'Dream' ? 'is-hidden' : 'image is-128x128 has-image-centered mb-1'}>
                <FaStar size={128} color={'#f7e151'} />
            </figure>            

            <div className={status === 'success' ? 'is-hidden' : 'is-block'}>
                <div className='errContainer has-height-1-and-half'>
                    {touched.feature && errors.feature && <small className='has-text-grey-dark has-text-weight-bold'>{errors.feature}</small>}
                </div>
              
                <div className='field has-addons has-addons-centered'>
                    <div className='control is-expanded'>
                        <Field className='textarea has-fixed-size has-height-8 br-left-18px' component='textarea' placeholder={'Your ' + type + ' Feature' } name='feature' />                       
                    </div>
                    <div className='control'>
                      <button className='button is-info has-height-8 br-right-18px has-width-5 has-font-weight-bold has-font-variant-petite-caps' type='submit' disabled={isSubmitting}>{type}</button>
                    </div>
                </div>
            </div>

            <div className={status === 'success' ? 'is-block' : 'is-hidden'}>
                <p className='control has-text-centered pb-1'>
                    <small className='has-text-grey-dark has-text-weight-bold'>Thank you</small>
                </p>
            </div>            
        </div>                    
      </form>)}
    />
  )
}

export { DreamFeatureForm }
