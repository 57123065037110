import React, { useContext } from "react"
import { Formik, Field } from 'formik'
import validationSchema from './validationSchema'
import { FirebaseContext } from "gatsby-plugin-firebase"

const EarlyAccessEmployerForm = () => {
  const firebase = useContext(FirebaseContext)

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const { email } = values;
        firebase.analytics().logEvent("early_access_employer_form_submission")

        firebase
          .firestore()
          .collection("/early_access_employers")
          .add({
            email,
            created: firebase.firestore.Timestamp.fromDate(new Date())
          })
          .then(() => {
            setSubmitting(false)
            setStatus('success')
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!");                            //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        status,
        isSubmitting,
        handleSubmit
      }) => (<form
        name='early_access'
        onSubmit={handleSubmit}
      >
        <div className={status === 'success' ? 'is-block' : 'is-hidden'}>
          <p className='control pt-2'>
          <small className='has-text-grey-dark has-text-weight-bold'>Sign up completed</small>
          </p>
        </div>

        <div className={status === 'success' ? 'is-hidden' : 'is-block'}>
          <p className='control pt-1'>
            <Field className='email input is-medium' type='email' placeholder='Your best email' name='email' />
            {touched.email && errors.email && <small className='has-text-grey-dark has-text-weight-bold'>{errors.email}</small>}
          </p>

          <p className='control pt-half'>
            <button className='button is-blue has-font-weight-bold has-font-variant-petite-caps' type='submit' disabled={isSubmitting}>EARLY ACCESS</button>
          </p>
        </div>
      </form>)}
    />
  )
}

export { EarlyAccessEmployerForm }
