import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

class LatestArticles extends React.Component {
  render() {
    const { edges: posts } = this.props.allMarkdownRemark

    return (
      <div className='container'>
        <div className='columns is-multiline is-centered'>

          {posts
            .map(({ node: post }) => (
              <div className='column is-4' key={post.id}>
                <Link className='has-text-primary' to={post.fields.slug}>
                  <div className='card is-shadowless is-floralwhite pg-2 br-18px has-height-100-percent'>
                    
                    <div className='card-image'>
                      <figure className="image is-128x128 has-image-centered">
                        <img src={post.frontmatter.cover}></img>
                      </figure>
                    </div>

                    <div>
                      <div className='mt-1'>
                        <p className='title is-4 has-text-grey-dark has-text-centered'>{post.frontmatter.title}</p>
                        <p className='subtitle is-6 has-text-centered'><small>{post.frontmatter.date}</small></p>
                      </div>

                      <hr></hr>

                      <div className='content'>
                        {post.excerpt}
                      </div>
                    </div>

                  </div>
                </Link>
              </div>

            ))}
        </div>
      </div>
    )
  }
}

const query = graphql`
  query LatestArticlesQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      limit: 3,
      filter: { frontmatter: { templateKey: { eq: "article-page" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 170)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            cover
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={data => <LatestArticles {...data} />}
  />
)